<template>
    <div>
        <app-page-header></app-page-header>
        <v-container  grid-list-xl>
            <!-- mobile -->
            <v-layout row wrap align-center justify-center fill-height v-if="renderComponents">
                <tabla-mobile 
                v-for="(tabla, i) in table" 
                :key="i" 
                :id="tabla.id_tabla"
                :tabla="tabla">
                </tabla-mobile>
            </v-layout>
        </v-container>

        <no-results :data="table" :naziv="'Semaforji'"></no-results>
    </div>
</template>

<script>
const TablaMobile = () =>  import('@/components/TablaMobile.vue')
const NoResults = () =>  import('@/components/NoResults.vue')

export default {
    components: {
        TablaMobile,
        NoResults,
    },

    data: () => ({
        renderComponents: false,
    }),

    watch: {

    },

    computed: {
        table() {
            if(this.$store.getters.search == '' || this.$store.getters.search == null) {
                return this.$store.getters.user.table.filter(tabla => tabla.id_tabla == 26)
            }

            return this.$store.getters.user.table.filter(tabla => {
               if(tabla.naziv.toLowerCase().includes(this.$store.getters.search.toLowerCase().trim()) && tabla.id_tabla == 26) {
                    return tabla;
                }

                let semaforjiLen = tabla.semaforji.length;
                let semaforFound = false;

                for(let i=0; i<semaforjiLen; i++) {
                    if(tabla.semaforji[i].naziv.toLowerCase().includes(this.$store.getters.search.toLowerCase().trim()) && tabla.id_tabla == 26) {
                        semaforFound = true;
                        break;
                    }
                }

                if(semaforFound) {
                    return tabla;
                }
            })
        }
    },

    methods: {
        getTable() {
            if(this.$store.getters.user != null && this.$store.getters.user.id != null) {
                this.$store.commit('SET_PROGRESS', true)
                this.$store.dispatch('TABLE', this.$store.getters.user.id)
                .then(response => {
                    this.$store.commit('setTable', response.data.data.table)
                    this.$store.commit('setStanja', response.data.data.stanja)
                    this.$store.commit('setNacini', response.data.data.nacini)

                    this.renderComponents = true

                    //console.log(response.data.data)
                })
                .catch(error => {
                    console.log(error)
                    this.text = 'Pri osveževanju semaforjev je prišlo do napake.'
                    this.snackbar = true
                })
                .finally(() => {
                    setTimeout(() => {
                        this.$store.commit('SET_PROGRESS', false)
                    }, 600)
                })
            }
        }
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name) 
        this.getTable();
    },

    mounted() {
        var vm = this
        setTimeout( () => {
            vm.renderComponents = true
        }, 700)
    },
    
    destroyed() {

    }
}

</script>

<style scoped>

</style>